import {Component, OnInit} from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public age: number;
  public ageError: string;
  public percent: number;
  public percentError: string;
  public salaryBase: number;
  public salaryBaseError: string;
  public accidentPlace: number;
  public accidentPlaceName: string;
  public generalError: string;
  public result: number;
  title = 'Busso & Torno Abogados';


  ngOnInit(): void {
    this.accidentPlaceName = 'En el trabajo';
    this.accidentPlace = 1;

    if ($('.toggle .toggle-title').hasClass('active')) {
      $('.toggle .toggle-title.active').closest('.toggle').find('.toggle-inner').show();
    }
    $('.toggle .toggle-title').click(function() {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active').closest('.toggle').find('.toggle-inner').slideUp(200);
      } else {
        $(this).addClass('active').closest('.toggle').find('.toggle-inner').slideDown(200);
      }
    });
  }

  modalResultOpen() {
    // tslint:disable-next-line:max-line-length
    if (this.age != null && this.percent != null && this.salaryBase != null && this.ageError === '' && this.salaryBaseError === '' && this.percentError === '') {
      this.generalError = '';
      if (this.accidentPlace === 1) {
        this.result = 53 * this.salaryBase * this.percent * 65 / this.age / 100;
        this.result = this.result + this.result * 0.2;
      }
      if (this.accidentPlace === 2) {
          this.result = 53 * this.salaryBase * this.percent * 65 / this.age / 100;
      }
      if (this.accidentPlace === 3) {
        this.result = 53 * this.salaryBase * 65 / this.age;
        // el sitio comenta que si se ejecuta este paso, pero el resultado que da no.
        // this.result = this.result + this.result * 0.2;
        this.result = this.result + 1046577;
      }
      $('#calculatorResult').modal('show');
    } else {
      this.generalError = 'Debe llenar todos los campos correctamente por favor.';
    }
  }

  smoothScroll() {
    const section = $('#back-to-top').attr('href');
    $('html, body').animate({
      scrollTop: ($(section).offset().top - 2)
    }, 1000, 'easeInOutExpo');
  }

  setAge() {
    // tslint:disable-next-line:radix
    this.age = parseInt($('#age').val());
    if (this.age >= 20 && this.age <= 120) {
      this.ageError = '';
    } else {
      this.ageError = 'La edad es un número entre el intervalo 20 - 120';
      this.age = 20;
    }
  }

  setPercent() {
    this.percent = parseFloat($('#percentI').val());
    if (this.percent >= 10 && this.percent <= 100) {
      this.percentError = '';
    } else {
      this.percentError = 'El porciento es un número entre el intervalo 10 - 100';
      this.percent = 10;
    }
  }

  setSalaryBase() {
    this.salaryBase = parseFloat($('#salaryBase').val());
    if (this.salaryBase >= 12000) {
      this.salaryBaseError = '';
    } else {
      this.salaryBaseError = 'El salario base se comprende mayor a $12000';
      this.salaryBase = 12000;
    }
  }

  setAccidentPlace() {
    // tslint:disable-next-line:radix
    this.accidentPlace = parseInt($('#accidentPlace').val());
    switch (this.accidentPlace) {
      case 1:
        this.accidentPlaceName = 'En el trabajo';
        break;
      case 2:
        this.accidentPlaceName = 'In-itínere';
        break;
      case 3:
        this.accidentPlaceName = 'Fallecimiento';
        break;
    }
  }
}
