<header>
  <!-- Navbar -->
  <nav class="navbar navbar-expand-lg navbar-light affix" id="mainNav">
    <div class="container">
      <a class="navbar-brand smooth-scroll" href="#">
        <img src="../assets/img/logosLetraClaroAbogados.jpg" alt="logo" height="70px" alt="logo">
      </a>
      <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
              data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item"><a class="nav-link smooth-scroll" href="#">Inicio</a></li>
          <li class="nav-item"><a class="nav-link smooth-scroll" href="#calculator">Calcular</a></li>
        </ul>
      </div>
    </div>
  </nav>
</header>

<section id="home">
  <div id="carousel" class="carousel slide carousel-fade" data-ride="carousel">
    <!-- Carousel items -->
    <div class="carousel-inner">
      <div class="carousel-item active slides">
        <div class="overlay"></div>
        <div class="slide-1"></div>
        <div class="hero ">
          <div class="wow fadeInUp">
            <h1 style="color: white; font-size: 28px">Calculadora de indemnización por accidente de trabajo o enfermedad</h1>
            <h2 style="color: white; font-size: 25px">¿<span><a href="" class="typewrite" data-period="2000"
                                                                data-type='[ "Las comisiones médicas perjudican", "Cómo podrían perjudicarme"]'>
                        <span class="wrap"></span></a></span>? </h2>

          </div>
          <a href="#faq-p1" class="btn btn-general btn-green wow fadeInUp" role="button">Instrúyase ahora!</a>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="about" class="about">
  <div class="container">
    <div class="row title-bar">
      <div class="col-md-12">
        <h2 class="wow fadeInUp text-center">¿Cómo calcular la Indemnización de la ART?</h2>
        <div class="heading-border"></div>
        <p class="wow fadeInUp" data-wow-delay="0.4s">El seguro de ART se convierte en uno de los más
          importantes en las empresas para tener
          todo en regla, evitar problemas con las inspecciones que puedan tener y también, desligarse de
          cualquier responsabilidad en caso de accidente leve, grave o fatal de sus empleados. Si desea
          calcular su indemnización de la ART imediatamente lo invitamos a usar nuestra calculadora aquí
          debajo, sino continúe leyendo para informarse mejor.</p>
      </div>
    </div>
  </div>
  <!-- About right side withBG parallax -->
</section>
<section id="calculator" class="bg-parallax contact-h-bg">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="contact-h-cont">
          <h2 class="cl-white">Calculadora</h2>
          <small style="color: darkred">{{generalError}}</small>
          <br>

          <form id="myForm">
            <div class="form-group cl-white">
              <label for="age">Edad (años)</label>
              <input type="number" class="form-control" id="age" (change)="setAge()" required
                     placeholder="Edad a la fecha del siniestro" min="20" max="120" step="1">
              <small style="color: darkred">{{ageError}}</small>
            </div>
            <div class="form-group cl-white">
              <label for="percentI">Porcentaje de incapacidad (%)</label>
              <input type="number" class="form-control" id="percentI" (change)="setPercent()" required
                     placeholder="Mínimo 10" min="10" max="100" step="0.01">
              <small style="color: darkred">{{percentError}}</small>
            </div>
            <div class="form-group cl-white">
              <label for="salaryBase">Ingreso Base Mensual ($)</label>
              <input type="number" class="form-control" id="salaryBase" (change)="setSalaryBase()" required
                     placeholder="Mínimo 12000.00" min="12000" step="0.01">
              <small style="color: darkred">{{salaryBaseError}}</small>
            </div>
            <div class="form-group cl-white">
              <label for="accidentPlace">Lugar de Accidente</label>
              <select name="accidentPlace" id="accidentPlace" class="form-control" (change)="setAccidentPlace()">
                <option value="1">En el trabajo</option>
                <option value="2">In-itínere</option>
                <option value="3">Fallecimiento</option>
              </select>
            </div>
            <a id="calcular" class="btn btn-white" role="button" (click)="modalResultOpen()"><i
              class="fa fa-right-arrow"></i>CALCULAR
            </a>
          </form>
        </div>
      </div>
      <div class="col-md-6 wow fadeInUp" style="background-color: white">
        <div class="service-h-desc">
          <h3 style="margin-top: 60px">Información</h3>
          <div class="heading-border-light"></div>
          <div class="service-h-tab" style="margin-top: 60px">
            <nav class="nav nav-tabs" id="myTab1" role="tablist">
              <a class="nav-item nav-link active" id="nav-age" data-toggle="tab" href="#nav-age-tab"
                 role="tab" aria-controls="nav-age" aria-expanded="true">Edad</a>
              <a class="nav-item nav-link" id="nav-salary" data-toggle="tab" href="#nav-salary-tab"
                 role="tab" aria-controls="nav-salary">Ingreso base</a>
              <a class="nav-item nav-link" id="my-accident" data-toggle="tab" href="#my-accident-tab"
                 role="tab" aria-controls="my-accident">Accidente in itinere</a>
              <a class="nav-item nav-link" id="my-moreinfo" data-toggle="tab" href="#my-moreinfo-tab"
                 role="tab" aria-controls="my-moreinfo">Aclaración</a>
            </nav>
            <div class="tab-content" id="nav-tabContent1">
              <div class="tab-pane fade show active" id="nav-age-tab" role="tabpanel"
                   aria-labelledby="nav-age-tab"><p>A la fecha del siniestro o primera manifestación de
                enfermedad. </p></div>
              <div class="tab-pane fade" id="nav-salary-tab" role="tabpanel"
                   aria-labelledby="nav-salary-tab">
                <p>Es el promedio mensual de todos los salarios devengados (salario es toda remuneración
                  o ganancia, sea cual fuere su denominación o método de cálculo, siempre que pueda
                  evaluarse en efectivo) durante el año anterior a la primera manifestación
                  invalidante, o en el tiempo de prestación de servicio si fuera menor. <br>Los
                  salarios
                  mensuales tomados a fin de establecer el promedio se actualizarán mes a mes
                  aplicándose la variación del índice RIPTE (Remuneraciones Imponibles Promedio de los
                  Trabajadores Estables). Por defecto, la calculadora toma como ingreso base mínimo el
                  establecido por la Nota SCE N° 5649/2017, equivalente a $ 1.234.994 x grado de
                  ILP/100.</p>
              </div>
              <div class="tab-pane fade" id="my-accident-tab" role="tabpanel"
                   aria-labelledby="my-accident-tab">
                <p>Es aquel que se produce en el trayecto entre el domicilio del trabajador y el lugar
                  de trabajo o viceversa. La Ley no establece que exista un límite de tiempo para que
                  el trabajador llegue a su destino siempre y cuando no hubiere interrumpido o
                  alterado su trayecto por causas ajenas al trabajo.</p>
              </div>
              <div class="tab-pane fade" id="my-moreinfo-tab" role="tabpanel"
                   aria-labelledby="my-moreinfo-tab">
                <p>Los importes indemnizatorios deben actualizarse conforme a los apartados 2º y 3º del
                  art. 12 de la ley 24.557 (confr. texto art. 11 de la ley complementaria 27.348).
                  <br>
                  La calculadora no está programada para liquidar las indemnizaciones por gran
                  invalidez o muerte del trabajador.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<br>
<section id="faq-p1" class="team-p1">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2 class="wow fadeInUp">¿Qué debe saber?</h2>
        <div class="faq-p1-cont">
          <div class="service-h-tab">
            <nav class="nav nav-tabs" id="myTab" role="tablist">
              <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home"
                 role="tab" aria-controls="nav-home" aria-expanded="true">A.R.T.</a>
              <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile"
                 role="tab" aria-controls="nav-profile">Reforma</a>
              <a class="nav-item nav-link" id="my-profile-tab" data-toggle="tab" href="#my-profile"
                 role="tab" aria-controls="my-profile">Incapacidad</a>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div class="tab-pane fade show active" id="nav-home" role="tabpanel"
                   aria-labelledby="nav-home-tab">
                <div class="toggle">
                  <div class="toggle-title ">
                    <h3>
                      <i></i>
                      <span class="title-name">¿Qué significa ART o Aseguradoras de Riegos de Trabajo?</span>
                    </h3>
                  </div>
                  <div class="toggle-inner">
                    <p>En el mundo de los seguros, el trabajo de las ART o Aseguradoras de Riegos de
                      Trabajo es uno de los más importantes porque resguardan la vida de los
                      trabajadores de todas las empresas. Lo interesante es aprender a cómo
                      calcular la indemnización de la ART.</p>
                  </div>
                </div>
                <div class="toggle">
                  <div class="toggle-title  ">
                    <h3>
                      <i></i>
                      <span class="title-name">¿Qué función cumple el seguro de ART?</span>
                    </h3>
                  </div>
                  <div class="toggle-inner">
                    <p>Su funcionamiento no es nada simple, y consta de muchos pasos para finalizar
                      cada cobertura, pero se activa fácilmente cuando un trabajador en relación
                      de dependencia sufre un accidente derivado de su labor. Es importante saber
                      que, el accidente puede ocurrir en el establecimiento así como también in
                      itinere, es decir, desde el lugar de trabajo hacia su casa, o viceversa.
                      <br>
                      Por ley, el empleador deberá cubrir todos los gastos que el accidente
                      laboral le genere al empleado. Para este tipo de gastos es que existe la
                      cobertura de ART para cada uno de los empleados. Lea este artículo si ha
                      sufrido un accidente laboral y no sabe qué hacer.</p>
                  </div>
                </div>
                <div class="toggle">
                  <div class="toggle-title  ">
                    <h3>
                      <i></i>
                      <span class="title-name">¿Cómo calcular la indemnización de la ART?</span>
                    </h3>
                  </div>
                  <div class="toggle-inner">
                    <p>Para poder calcular la indemnización de la ART, veamos el caso de que un
                      empleado sufra un accidente de trabajo y éste le genere una incapacidad.
                      Claro está que no siempre el accidente es fatal y por lo cual debe ser
                      medido y calculado el grado de incapacidad que le ha dejado de regalo este
                      accidente.
                      <br>
                      En concreto, se dice que existe incapacidad cuando el daño es tal que el
                      trabajador no podrá utilizar al 100% de su fuerza de trabajo.
                    </p>
                  </div>
                </div>
                <div class="toggle">
                  <div class="toggle-title  ">
                    <h3>
                      <i></i>
                      <span class="title-name"> ¿Cómo determinar el Ingreso Base Mensual?</span>
                    </h3>
                  </div>
                  <div class="toggle-inner">
                    <p>Para poder determinar el ingreso base mensual, debemos dividir la suma total
                      de las remuneraciones sujetas a aportes (conceptos remunerativos), de los 12
                      meses anteriores al accidente, por el total de días del año. Si el tiempo de
                      trabajo es menos a un año, se divide por el número de días corridos
                      trabajados.
                      <br>
                      Tras obtener el valor de nuestro salario diario, el Ingreso Base Mensual se
                      obtiene al multiplicar la cantidad obtenida según el párrafo anterior por el
                      factor 30,4.</p>
                  </div>
                </div>
                <div class="toggle">
                  <div class="toggle-title  ">
                    <h3>
                      <i></i>
                      <span class="title-name">¿Cuánto paga la ART por punto de incapacidad?</span>
                    </h3>
                  </div>
                  <div class="toggle-inner">
                    <p>Este tipo de preguntas son habituales entre los que reciben tratamiento
                      médico de las ART. Es importante saber que no hay montos definidos fijos por
                      punto de incapacidad, sino que dependen siempre del salario del trabajador.
                      <br>
                      Sin embargo, podemos saber cuanto paga la ART por punto de incapacidad como
                      mínimo, dado que existe un tope base sobre el que se aplica el porcentaje de
                      incapacidad.
                      <br>
                      En este sentido, tomando el tope mínimo de hoy en día como $1.569.865,
                      podemos afirmar que el 1% se traduce en una indemnización de 15698,65 pesos.
                    </p>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="nav-profile" role="tabpanel"
                   aria-labelledby="nav-profile-tab">
                <div class="toggle">
                  <div class="toggle-title ">
                    <h3>
                      <i></i>
                      <span class="title-name">Reforma de la Ley de ART</span>
                    </h3>
                  </div>
                  <div class="toggle-inner">
                    <p>A fines del año pasado, se ha modificado y reformado la ley de ART.</p>
                  </div>
                </div>
                <div class="toggle">
                  <div class="toggle-title  ">
                    <h3>
                      <i></i>
                      <span class="title-name"> Eliminación de la “doble vía”</span>
                    </h3>
                  </div>

                  <div class="toggle-inner">
                    <p>Lo que anteriormente sucedía era que el trabajador podía acordar una cierta
                      suma con la ART luego no podrá realizar un reclamo por vía judicial. Claro
                      está que era un punto que perjudicaba al trabajador que muchas veces se
                      encontraba en inferioridad de condiciones y debía aceptar lo que la ART le
                      daba y eso era lo último que veía en materia indemnizatoria. Ahora, podrá
                      reclamar a ambas partes y cobrar lo que realmente merece.</p>
                  </div>
                </div>
                <div class="toggle">
                  <div class="toggle-title  ">
                    <h3>
                      <i></i>
                      <span class="title-name"> Incremento de la indemnización de la ART</span>
                    </h3>
                  </div>
                  <div class="toggle-inner">
                    <p>La ley fija un tope mínimo de indemnización de la ART para el empleado, esta
                      no puede tener en cuenta todas las situación agravantes como los atenuantes
                      que existan en cada caso ya que las circunstancias entre un caso y otro
                      pueden diferir en gran medida y no es el espíritu de la ley.</p>
                  </div>
                </div>
                <div class="toggle">
                  <div class="toggle-title  ">
                    <h3>
                      <i></i>
                      <span class="title-name"> Aumento de la prima</span>
                    </h3>
                  </div>
                  <div class="toggle-inner">
                    <p>Elevará la actual alícuota promedio del sistema del 3,1% al 3,59% de la masa
                      salarial de cada empresa</p>
                  </div>
                </div>
                <div class="toggle">
                  <div class="toggle-title  ">
                    <h3>
                      <i></i>
                      <span class="title-name">Ajuste de indemnizaciones</span>
                    </h3>
                  </div>
                  <div class="toggle-inner">
                    <p>Todas las indemnizaciones se ajustarán semestralmente por RIPTE,
                      (Remuneraciones Imponibles Promedio de los Trabajadores Estables), que es el
                      índice que mejor refleja la evolución de los salarios.</p>
                  </div>
                </div>
                <div class="toggle">
                  <div class="toggle-title  ">
                    <h3>
                      <i></i>
                      <span class="title-name">Régimen de “opción excluyente”</span>
                    </h3>
                  </div>
                  <div class="toggle-inner">
                    <p>Entre la indemnización establecida por la ley o la demanda judicial. Se
                      establece un plazo de 15 días a partir del accidente como máximo para que el
                      trabajador cobre la indemnización.</p>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="my-profile" role="tabpanel" aria-labelledby="my-profile-tab">
                <div class="toggle">
                  <div class="toggle-title ">
                    <h3>
                      <i></i>
                      <span class="title-name">Incapacidad determinada – hasta el 49,99% Incapacidad Laboral Permanente Parcial</span>
                    </h3>
                  </div>
                  <div class="toggle-inner">
                    <p>
                      Pago único: el trabajador recibe un pago único dada su incapacidad, por no
                      ser total <br>
                      Cálculo de la indemnización de la ART: 53 x Ingreso Base Mensual x % de
                      incapacidad x 65 / edad. <br>
                      Provisionalidad: No se aplica la provisionalidad en este tipo de incapacidad
                      <br>
                      Tope Mínimo: la indemnización de la ART no podrá ser inferior al resultado
                      de 1,569,865 pesos por el porcentaje de incapacidad (más la tasa activa
                      cartera general nominal anual vencida a treinta días del Banco de la Nación
                      Argentina). <br>
                      Adicional por daños morales y psicológicos: 20% sobre la indemnización
                      determinada.
                    </p>
                  </div>
                </div>
                <div class="toggle">
                  <div class="toggle-title  ">
                    <h3>
                      <i></i>
                      <span class="title-name"> Incapacidad determinada – entre el 50-65,99% Incapacidad Laboral Provisoria Parcial</span>
                    </h3>
                  </div>

                  <div class="toggle-inner">
                    <p>
                      Tiempo: 36 meses desde la declaración de incapacidad por la Comisión Médica,
                      extensible hasta por 24 meses más. <br>
                      Tipo de pago: mensual. <br>
                      Cálculo de la indemnización de la ART: 100% del Ingreso Base Mensual x % de
                      incapacidad + Asignaciones Familiares
                    </p>
                  </div>
                </div>
                <div class="toggle">
                  <div class="toggle-title  ">
                    <h3>
                      <i></i>
                      <span class="title-name">Incapacidad determinada – entre el 50-65,99% Definitiva Parcial</span>
                    </h3>
                  </div>
                  <div class="toggle-inner">
                    <p>
                      Tipo de pago: Renta periódica (de carácter vitalicio) – Se suele pagar en
                      una exhibición. <br>
                      Cálculo de la indemnización de la ART: 100% del Ingreso Base Mensual x % de
                      incapacidad + Asignaciones Familiares. <br>
                      Tope mínimo: nunca podrá ser inferior a los 1,569,865 pesos por el
                      porcentaje de incapacidad (más la tasa activa cartera general nominal anual
                      vencida a treinta días del Banco de la Nación Argentina). <br>
                      Prestación adicional de pago único de: 697.718 pesos. <br>
                      Adicional por daños morales y psicológicos: 20% sobre la indemnización
                      determinada. <br>
                    </p>
                  </div>
                </div>
                <div class="toggle">
                  <div class="toggle-title  ">
                    <h3>
                      <i></i>
                      <span class="title-name">Incapacidad determinada – 66% o más Provisoria Total</span>
                    </h3>
                  </div>
                  <div class="toggle-inner">
                    <p>
                      Se abonará por unos 36 meses desde la declaración de incapacidad por la
                      Comisión Médica, extensible por 24 meses más. <br>
                      Pago Mensual <br>
                      Cálculo de la indemnización de la ART: 70% del Ingreso Base Mensual +
                      Asignaciones Familiares.
                    </p>
                  </div>
                </div>
                <div class="toggle">
                  <div class="toggle-title  ">
                    <h3>
                      <i></i>
                      <span class="title-name">Incapacidad determinada – 66% o más Definitiva Total</span>
                    </h3>
                  </div>
                  <div class="toggle-inner">
                    <p>
                      Tipo de Pago: Renta periódica (de carácter vitalicio) – Se suele pagar en
                      una exhibición. <br>
                      Cálculo de la Indemnización: 53 x Ingreso Base Mensual x 65/ edad. <br>
                      Tope mínimo: 1,569,865 pesos por el porcentaje de incapacidad (más la tasa
                      activa cartera general nominal anual vencida a treinta días del Banco de la
                      Nación Argentina). <br>
                      Provisionalidad: percibirá la prestación provisional. <br>
                      Prestación adicional de pago único: 872.147 pesos. <br>
                      Adicional por daños morales y psicológicos: 20% sobre la indemnización
                      determinada. <br>
                    </p>
                  </div>
                </div>
                <div class="toggle">
                  <div class="toggle-title  ">
                    <h3>
                      <i></i>
                      <span class="title-name">Gran Invalidez</span>
                    </h3>
                  </div>
                  <div class="toggle-inner">
                    <p>Este es un caso aparte, dado que existe Gran Invalidez siempre y cuando la
                      situación de incapacidad laboral sea permanente total y necesite la
                      asistencia continua de otra persona para realizar los actos elementales de
                      su vida.</p>
                  </div>
                </div>
                <div class="toggle">
                  <div class="toggle-title  ">
                    <h3>
                      <i></i>
                      <span class="title-name">Incapacidad determinada – Gran Invalidez Provisoria</span>
                    </h3>
                  </div>
                  <div class="toggle-inner">
                    <p>Cálculo de la indemnización de la ART: 70% del Ingreso Base Mensual +
                      Asignaciones Familiares. + 3 Mo.Pre (Previamente AMPO).</p>
                  </div>
                </div>
                <div class="toggle">
                  <div class="toggle-title  ">
                    <h3>
                      <i></i>
                      <span class="title-name">Incapacidad determinada – Gran Invalidez Definitiva</span>
                    </h3>
                  </div>
                  <div class="toggle-inner">
                    <p>
                      Tipo de Pago: Renta periódica (de carácter vitalicio) <br>
                      Cálculo de la indemnización de la ART: 53 x Ingreso Base Mensual x 65/ edad
                      + 3 Mo.Pre <br>
                      Tope mínimo: 1,569,865 pesos por el porcentaje de incapacidad (más la tasa
                      activa cartera general nominal anual vencida a treinta días del Banco de la
                      Nación Argentina). <br>
                      Provisionalidad: percibirá la prestación provisional. <br>
                      Prestación adicional de pago único: 697.718 pesos. <br>
                      Adicional por daños morales y psicológicos: 20% sobre la indemnización
                      determinada.
                    </p>
                  </div>
                </div>
                <div class="toggle">
                  <div class="toggle-title  ">
                    <h3>
                      <i></i>
                      <span class="title-name">Fallecimiento</span>
                    </h3>
                  </div>
                  <div class="toggle-inner">
                    <p>

                      Tipo de pago: Renta periódica. <br>
                      Cálculo de la indemnización: 53 x Ingreso Base Mensual x 65/edad. <br>
                      Provisionalidad: Se percibirá la renta prevista en el régimen provisional.
                      Prestación adicional de pago único: 1,046,577 pesos. <br>
                      Adicional por daños morales y psicológicos: 20% sobre la indemnización
                      determinada.

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div #MyModal class="modal fade" id="calculatorResult" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true" style="display: none;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" align="center">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span class="fa fa-times" aria-hidden="true"></span>
        </button>
      </div>
      <div id="div-forms">
        <form id="login-form">
          <h3 class="text-center">Calculado para:</h3>
          <div class="modal-body">
            <label>Edad: <b style="font-size: 20px">{{age}} años</b></label> <br>
            <label>Porcentaje: <b style="font-size: 20px">{{percent}}%</b></label> <br>
            <label>Ingreso: <b style="font-size: 20px">${{salaryBase | number: '1.2-2'}}</b></label> <br>
            <label>Lugar: <b style="font-size: 20px">{{accidentPlaceName}}</b></label> <br><br>
            <label  style="font-size: 25px; background-color: #2196F3; color: white;"> Resultado:
              <b>${{result | number: '1.2-2'}}</b></label>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<footer>
  <div id="footer-s1" class="footer-s1">
    <div class="footer">
      <div class="container">
        <div class="row">
          <!-- About Us -->
          <div class="col-md-4 col-sm-12">
            <div><img src="../assets/img/logosgrisLetrasAbogados.jpg" alt="logo" class="img-fluid"></div>
            <ul class="list-unstyled comp-desc-f">
              <li><p>Estamos para defender y proteger sus derechos mediante la resolución de conflictos
                jurídicos y legales.
                Ética, lealtad y privacidad nos define. Profesionalidad, dedicación y responsabilidad
                nos distingue.
              </p></li>
            </ul>
            <br>
          </div>
          <div class="col-md-4 col-sm-12"></div>
          <!-- Latest Tweets -->
          <div class="col-md-4 col-sm-12">
            <div style="margin-left: 20px; margin-top: 70px;">
              <div class="heading-footer"><h2>Contactos</h2></div>
              <address class="address-details-f">
                Noroeste Bonaerense <br>
                Sur de Santa Fe <br>
              </address>
              <ul class="list-inline social-icon-f top-data" style="margin-left: -15px">
                <li><a href="https://wa.me/5493415030903?text=Saludos%20Lic.%20Busso,%20Necesito%20de%20sus%20servicios."
                       target="_empty"><i
                  class="fa top-social fa-whatsapp"
                  style="background-color: #1e7e34; color: white"></i> + 54 9 3415 03-0903 Mariano
                  Busso</a>
                </li>
                <li><a href="https://wa.me/5493412802920?text=Saludos%20Lic.%20Torno,%20Necesito%20de%20sus%20servicios."
                       target="_empty"><i
                  class="fa top-social fa-whatsapp"
                  style="background-color: #1e7e34; color: white"></i> + 54 9 3412 80-2920 Héctor
                  Martín
                  Torno</a></li>
              </ul>
            </div>
          </div>
          <!-- End Latest Tweets -->
        </div>
      </div><!--/container -->
    </div>
  </div>

  <div id="footer-bottom">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div id="footer-copyrights">
            <p>Copyright &copy; 2020 All Rights Reserved by Busso & Torno Abogados.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a href="#page-top" id="back-to-top" (click)="smoothScroll()" class="btn btn-sm btn-green btn-back-to-top hidden-sm hidden-xs"
     title="home" role="button">
    <i class="fa fa-angle-up"></i>
  </a>
</footer>


